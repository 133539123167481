import tagmanager from "../../../abstracts/tagmanager";
import baseFilter from "./baseFilter";

const tours = () => ({
    ... baseFilter(),

    modalId: 'tour-filter',

    close(evt) {
        if (evt?.detail?.name !== this.modalId) {
            return
        }

        let trackingData = {
            'Category': 'tour',
        }

        tagmanager.event(
            this.currentFilter ? 'comp_quick-filter_close' : 'comp_filter_close',
            trackingData
        )
    },
})

export default tours
