import tagmanager from '../../abstracts/tagmanager';

const tours = (baseUrl, filter) => ({

    currentFilter: false,
    params: {baseUrl},

    init() {
        if (!filter) {
            return
        }
        this.params = {...this.params, ...filter}
    },

    onSearchResultSelected(data) {
        window.location.href = data.attributes.path;
    },

    openFilter(label, filter, url) {
        this.currentFilter = filter

        const trackingData = {
            'Category': 'tour',
            'Selection': filter,
        }

        tagmanager.event(
            this.currentFilter ? 'comp_quick-filter_open' : 'comp_filter_open',
            trackingData
        )

        this.isSearchModal = filter && filter === "regions";
        this.ignoreHistory = url.includes('toursFilterTypes')
        this.$dispatch('modal-page-header', label)

        this.toggle(url)
    },

    filter(event) {
        if (!event.detail.filters) {
            return
        }

        this.params = {...this.params, ...event.detail.filters}
        Object.keys(this.params).forEach((k) => this.params[k] == null && delete this.params[k]);

        this.trackFilter()
    },

    trackFilter() {
        const {
            page,
            baseUrl,
            ...selectedFilters
        } = this.params

        tagmanager.event(
            this.currentFilter ? 'comp_quick-filter_submit' : 'comp_filter_submit',
            this.addLocationToTrackingData({
                Category: 'tour',
                Selection: '' + new URLSearchParams(this.getUrlParams()),
                Filters: selectedFilters
            }, this.params)
        )
    },

    getUrlParams() {
        let params = []

        Object.keys(this.params).forEach((key) => {
            let value = this.params[key]
            if (Array.isArray(value)) {
                for (let i in value) {
                    params.push([key + "[]", this.params[key][i]])
                }
                return
            }

            params.push([key, this.params[key]])
        })

        return params
    }
})

export default tours
